<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-radio-group v-model="type" class="vg_mr_8" size="mini" @change="getDataList">
          <el-radio-button :label="6">采购一部</el-radio-button>
          <el-radio-button :label="7">采购二部</el-radio-button>
          <el-radio-button :label="20">辅料部</el-radio-button>
        </el-radio-group>
        <el-date-picker
          v-model="dateRange"
          class="vg_mr_8 topDateRangeSearch"
          end-placeholder="合同结束日期"
          range-separator="至"
          size="small"
          start-placeholder="合同开始日期"
          type="daterange"
          value-format="timestamp"
        />
        <el-date-picker
          v-model="dateRange2"
          class="vg_mr_8 topDateRangeSearch"
          end-placeholder="预计结束日期"
          range-separator="至"
          size="small"
          start-placeholder="预计开始日期"
          type="daterange"
          value-format="timestamp"
        />
        <el-button class="vd_export" size="small" type="primary" @click="getDataList()">查询</el-button>
        <el-button class="vd_export" icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()"> 刷新 </el-button>
        <el-button class="vd_export" size="small" type="success" @click="exportExcel()"> 导出Excel </el-button>
      </div>
      <DynamicUTable
        ref="partMultiTable"
        v-loading="loadFlag"
        :columns="tableProperties"
        :need-check-box="false"
        :need-fixed-height="true"
        :need-page-sizes="true"
        :need-search="true"
        :needAllColumnSummary="true"
        :page-size="50"
        :showSummary="true"
        :skip-summary-index="[1, 2, 3, 4, 5, 6, 7, 8]"
        :tableData="tableData"
        :totalPage="totalPage"
        @getTableData="getDataList"
      >
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { tableProperties } from '@/views/StatisticsManagement/PomodrDetailStatistics/pdd';
import { downloadFile, setTime } from '@api/public';
import { stisAPI } from '@api/modules/stis';

export default {
  name: 'pomodrSumStatistics',
  components: {
    DynamicUTable
  },
  data() {
    return {
      dateRange: [],
      dateRange2: [],
      tableProperties: cloneDeep(tableProperties),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      type: 0
    };
  },
  created() {
    this.type = this.$cookies.get('userInfo').dept_id;
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getDataList();
      this.changeTableProperties();
    },
    async changeTableProperties() {},
    getSearchForm() {
      let searchForm = cloneDeep(this.$refs.partMultiTable.searchForm);
      searchForm.type = this.type;
      searchForm.monthList = searchForm.monthList?.toString();
      setTime(searchForm, 'start_time', 'end_time', this.dateRange, true);
      setTime(searchForm, 'start_time2', 'end_time2', this.dateRange2, true);
      return searchForm;
    },
    //获取数据
    async getDataList() {
      this.loadFlag = true;
      let searchForm = this.getSearchForm();
      await stisAPI.getPoModrDetalisStatisticsList(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
        this.tableProperties.find(x => x.label === '采购人员').options = cloneDeep(data.stffNameList);
        this.tableProperties.find(x => x.label === '供应商').options = cloneDeep(data.suppAbbrList);
      });
    },
    //跨分页导出excel
    exportExcel() {
      stisAPI.excelPoModrDetalisStatistics(this.getSearchForm()).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '' });
      });
    },
    //刷新
    buttonRefresh() {
      this.$refs.partMultiTable.resetFields();
      this.dateRange = [];
      this.dateRange2 = [];
      this.getDataList();
    }
  }
};
</script>

<style lang="scss" scoped>
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
